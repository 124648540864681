/* eslint-disable no-unused-vars */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Show, useDataProvider, useNotify, useRefresh, downloadCSV } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import uiColor from '../../utils/utilsColors';
import { BoxedShowLayout, RaBox } from 'ra-compact-ui';
import { ValueCodeListsHeaderGrids } from './ValueCodeListsHeaderGrids';
import ValueCodesFromList from './ValueCodesFromList';
import { ValueCodesFilters } from '../valuecodes/ValueCodesFilters';
import jsonExport from 'jsonexport/dist';
import { hasViewById } from '../../utils/permissions';

/**
 * Read only list of branches
 * https://marmelab.com/react-admin/doc/2.8/Show.html
 * @param props
 * @constructor
 */
export const ValueCoeListShow = (props: any, { record }: any) => {
	const classes = useStyles();
	const history = useHistory();
	const notify = useNotify();
	const [curSort] = useState({});
	const [total] = useState(0);
	const [search, searchInput] = useState('');
	const refresh = useRefresh();
	const dataProvider = useDataProvider();
	const [valueCodes, setValueCodes] = useState([]);
	const [filter, setFilter] = useState<any>({ ValueCodeListId: props.id });

	const formatValueCodeHRID = (humanReadableId: string) => {
		if (humanReadableId) {
			if (humanReadableId.length === 12) {
				return (
					humanReadableId.slice(0, 3) +
					'-' +
					humanReadableId.slice(3, 6) +
					'-' +
					humanReadableId.slice(6, 9) +
					'-' +
					humanReadableId.slice(9)
				);
			} else return humanReadableId;
		} else return '';
	};

	const onExports = (exportType: any) => {
		dataProvider
			.getList(`valuecodes`, {
				filter: { ...filter, ValueCodeListId: props.id },
				sort: { field: 'createdOn', order: 'ASC' },
				pagination: { page: 1, perPage: 1000000000 }
			})
			.then((valueCodesList: any) => {
				let valueCodesForExport = [];
				if (exportType.myValue === 'default') {
					valueCodesForExport = valueCodesList.data.map((valueCode: any) => ({
						Id: valueCode.id,
						ValueCodeType: valueCode.valueCodeType,
						HumanReadableId: valueCode.humanReadableId,
						CreatedOn: valueCode.createdOn,
						ExpirationDate: valueCode.expirationDate,
						LocationId: valueCode.locationId,
						ValueStoreId: valueCode.valueStoreId,
						ServiceId: valueCode.serviceId,
						ValueCodeListId: valueCode.valueCodeListId,
						Active: valueCode.active
					}));
				} else if (exportType.myValue === 'cxj') {
					valueCodesForExport = valueCodesList.data.map((valueCode: any) => ({
						"Human Readable Id": valueCode.humanReadableId.replace(/(.{3})/g, '$1-').slice(0, -1),
						UUID: valueCode.id,
						"NFC UID": ""
					}));
				} else {
					valueCodesForExport = valueCodesList.data.map((valueCode: any) => ({
						MimeType: 'application/json',
						PayloadASCII: valueCode.id,
						PayloadHex: '',
						ReadOnly: true,
						Identity: '',
						Notes: '',
						Data1: formatValueCodeHRID(valueCode.humanReadableId),
						Data2: valueCode.humanReadableId
							? 'https://mycard.cscsw.com?HRID=' + valueCode.humanReadableId
							: '',
						Data3: '',
						Data4: '',
						Data5: '',
						Data6: '',
						Data7: '',
						Data8: '',
						Data9: '',
						Data10: ''
					}));
				}
				jsonExport(
					valueCodesForExport,
					{
						headers: []
					},
					// eslint-disable-next-line n/handle-callback-err
					(err, csv) => {
						if (exportType.myValue === 'default') {
							downloadCSV(csv, 'valueCodes');
						} else if (exportType.myValue === 'cxj') {
							downloadCSV(csv, 'CXJRFIDFactoryValueCodes');
						} else {
							downloadCSV(csv, 'goToTagsValueCodes');
						}
					}
				);
			});
	};

	useEffect(() => {
		if (hasViewById(null, 'valueCodeLists', props.id) === false) {
			notify(`Unauthorized`, 'warning');
			history.push(`${props.basePath}`);
		}
	}, [props.id]);

	return (
		<>
			<Show {...props}>
				<BoxedShowLayout className={classes.showLayout}>
					<RaBox>
						<ValueCodeListsHeaderGrids
							searchInput={searchInput}
							idSearch='id'
							disableExport={true}
							curSort={curSort}
							total={total}
							refresh={refresh}
							onExports={onExports}
						/>
					</RaBox>
					<RaBox marginTop='20px' marginLeft='40px' marginRight='40px'>
						<ValueCodesFilters filter={filter} setFilter={setFilter} onExports={onExports} />
						<ValueCodesFromList
							{...props}
							setValueCodes={setValueCodes}
							filter={filter}
							setFilter={setFilter}
						/>
					</RaBox>
				</BoxedShowLayout>
			</Show>
		</>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		roomList: {
			background: 'white',
			marginTop: '16px'
		},
		showRoot: {},
		showNoActions: {
			marginTop: '20px'
		},
		showCard: {
			boxShadow: '0 0 0'
		},
		showLayout: {
			padding: 0,
			'&:first-child': {
				paddingTop: '0px'
			},
			'&:last-child': {
				paddingBottom: '0px'
			}
		},
		gridWrapper: {
			display: 'flex',
			margin: '0px 40px'
		},
		table: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			borderRadius: '8px',
			background: '#FFFFFF',
			minWidth: '1191px'
		},
		thead: {
			borderTopLeftRadius: '8px',
			borderTopRightRadius: '8px'
		},
		tbody: {
			borderBottomLeftRadius: '8px',
			borderBottomRightRadius: '8px',
			border: '1px solid #EEEFF3'
		},
		headerCell: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 'bold',
			fontSize: '16px',
			lineHeight: '22px',
			letterSpacing: '-0.5px',
			color: '#111111',
			paddingTop: '12px',
			paddingBottom: '15px',
			paddingLeft: '24px',
			'&:first-child': {
				width: '20px',
				paddingLeft: '0',
				paddingRight: '0'
			},
			'&:nth-child(2)': {
				width: '350px'
			},
			'&:last-child': {
				width: '50px'
			}
		},
		locationName: {
			fontWeight: 'bold',
			color: uiColor.secondary,
			letterSpacing: '-0.5px'
		},
		tableRow: {
			'&$hover:hover': {
				background: 'linear-gradient(269.92deg, #EEEFF3 4.93%, #F2F4FB 99.9%)',
				cursor: 'pointer'
			}
		},
		hover: {},
		tableCell: {
			paddingTop: '16px',
			paddingBottom: '16px',
			borderRight: '1px solid #EEEFF3',
			paddingLeft: '24px'
		},
		tableCellNoBorder: {
			paddingTop: '16px',
			paddingBottom: '16px',
			paddingLeft: '24px'
		},
		showBtn: {
			visibility: 'hidden',
			'$hover:hover &': {
				visibility: 'visible'
			}
		},
		normalField: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontWeight: 500,
			fontSize: '16px',
			lineHeight: '22px',
			color: '#111111'
		},
		addRoomBtnRoot: {
			boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
			padding: '1px 0px',
			borderRadius: '8px',
			width: '154px',
			height: '36px',
			background: 'linear-gradient(90deg, #009DE0 8%, #0CB6FF 100%)'
		},
		addRoomBtnTxt: {
			color: '#FFFFFF',
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			fontSize: '16px',
			lineHeight: '22px',
			fontWeight: 'bold',
			letterSpacing: '0.5px'
		},
		tabsRoot: {
			borderBottom: '1px solid lightgray',
			padding: '0 40px'
		},
		tabsIndicator: {
			background: '#1695CB'
		},
		tabRoot: {
			fontFamily: 'Manrope',
			fontStyle: 'normal',
			letterSpacing: '0.75px',
			fontWeight: 'bold',
			fontSize: '18px',
			lineHeight: '25px',
			color: '#969696',
			padding: 0,
			minWidth: '73px',
			marginRight: '32px'
		},
		tabSelected: {
			color: '#1695CB'
		},
		humanReadableId: {
			fontWeight: 'bold',
			color: '#1695CB',
			cursor: 'pointer'
		}
	})
);
