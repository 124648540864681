import * as React from 'react';
import { forwardRef, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import {
	Typography,
	Button,
	Dialog,
	Slide,
	AppBar,
	Toolbar,
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	IconButton
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import useStyles from '../styles';
import { AddValueCodeListProps } from './DialogsProps';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NumberFormat from 'react-number-format';
import { useNotify, useDataProvider, HttpError } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import Delete from '@material-ui/icons/Delete';
import DropdownTreeSelect from 'react-dropdown-tree-select';
import DatePicker from 'react-datepicker';
import CustomInput from '../CustomInput';
import { format } from 'date-fns';
import 'react-dropdown-tree-select/dist/styles.css';

const Transition = forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement },
	ref: React.Ref<unknown>
) {
	return <Slide direction='up' ref={ref} {...props} />;
});

interface State {
	totalCodes: string;
	amountPerCode: string;
}
interface Expiration {
	validityPeriod: any;
	expirationDate: any;
}
interface InitialDeposit {
	currency?: Object;
	amount?: Object;
	deleteButtons?: Object;
	index?: number;
}
interface initialAmount {
	currencyId?: string;
	currencyName?: string;
	amount?: string;
	monetary?: boolean;
}
interface NumberFormatCustomProps {
	inputRef: (instance: NumberFormat<any> | null) => void;
	onChange: (event: { target: { name: string; value: string } }) => void;
	name: string;
}

function IntegerFormatter(props: NumberFormatCustomProps) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			allowNegative={false}
			decimalScale={0}
		/>
	);
}

function DecimalFormatter(props: NumberFormatCustomProps) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			allowNegative={false}
			decimalScale={2}
			fixedDecimalScale
		/>
	);
}

function TotalCodesFormatter(props: NumberFormatCustomProps) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			isNumericString
			allowNegative={false}
			decimalScale={0}
		/>
	);
}

export const AddValueCodeListDialog = ({
	openAddValueCodeList,
	addValueCodeListClosed,
	addValueCodeListClicked,
	locations,
	services
}: AddValueCodeListProps) => {
	const classes = useStyles();
	const [listName, setlistName] = useState('');
	const [expirationSelect, setExpirationSelect] = useState('noExpiration');
	const [expiration, setExpiration] = useState<Expiration | null>({
		expirationDate: 'All',
		validityPeriod: null
	});
	const [validFromFirstTransaction, setValidFromFirstTransaction] = useState<boolean>(false);
	const [openCalendar, setOpenCalendar] = useState(false);
	const [service, setService] = useState('');
	const [selectedService, setSelectedService] = useState('');
	const [location, setLocation] = useState('');
	const [selectedLocation, setSelectedLocation] = useState('');
	const [step, setStep] = useState(1);
	const [currencyTypes, setCurrencyTypes] = useState<any[]>([]);
	const [currencyTypesCountToSelect, setCurrencyTypesCountToSelect] = useState(0);
	const [initialAmountIndex, setInitialAmountIndex] = useState(0);
	const [currenciesAndAmountsElements, setCurrenciesAndAmountsElements] = useState<
		InitialDeposit[]
	>([]);
	const [currenciesAndAmounts, setCurrenciesAndAmounts] = useState<initialAmount[]>([]);
	const [values, setValues] = React.useState<State>({
		totalCodes: '',
		amountPerCode: ''
	});
	const [showInitialDepositsTitle, setShowInitialDepositsTitle] = useState(false);
	const [locationsToSelect, setLocationsToSelect] = useState<any>([]);

	const dataProvider = useDataProvider();
	const notify = useNotify();

	useEffect(() => {
		function handlekeydownEvent(event: any) {
			const { keyCode } = event;
			if (keyCode === 27) {
				cancelClicked();
			}
		}

		document.addEventListener('keyup', handlekeydownEvent);
		return () => {
			document.removeEventListener('keyup', handlekeydownEvent);
		};
	}, []);

	const onCreateListClicked = () => {
		cancelClicked();
		addValueCodeListClicked({
			service,
			listName,
			location,
			values,
			currenciesAndAmounts,
			expiration,
			validFromFirstTransaction
		});
		addValueCodeListClosed();
	};

	const getCurrencyTypesFromService = (serviceId: string) => {
		dataProvider
			.getList(`services/${serviceId}/currencytypes`, {
				filter: {},
				sort: { field: 'name', order: 'ASC' },
				pagination: { page: 0, perPage: 1000000 }
			})
			.then(({ data }) => {
				setCurrencyTypes(data);
				setCurrencyTypesCountToSelect(data.length);
				for (let index = 0; index < data.length; index++) {
					setCurrenciesAndAmounts((oldArray) => [
						...oldArray,
						{
							currencyId: '',
							currencyName: '',
							amount: ''
						}
					]);
				}
			})
			.catch((error) => {
				notify('Error getting services: ' + error, 'warning');
			});
	};

	const handleSelectChange = (event: any) => {
		if (event.target.name === 'service') {
			setService(event.target.value);
			getCurrencyTypesFromService(event.target.value);
			services.forEach((service: any) => {
				if (service.id === event.target.value) setSelectedService(service.name);
			});
		}
		if (event.target.name === 'expiration') {
			setExpirationSelect(event.target.value);
			setExpiration({
				expirationDate: 'All',
				validityPeriod: ''
			});
			if (event.target.value === 'periodAfterFirstTransaction') setValidFromFirstTransaction(true);
		}
		if (event.target.name.substring(0, 8) === 'currency') {
			const items = currenciesAndAmounts.slice();
			items[parseInt(event.target.name.substring(8, 9))].currencyId = event.target.value;
			items[parseInt(event.target.name.substring(8, 9))].amount = '';
			currencyTypes.forEach((element) => {
				if (element.id === event.target.value) {
					items[parseInt(event.target.name.substring(8, 9))].monetary = element.monetary;
				}
			});
			setCurrenciesAndAmounts(items);
			if (parseInt(event.target.name.substring(8, 9)) !== 0) {
				const amountAndCurrencyElements = currenciesAndAmountsElements.slice();
				if (items[parseInt(event.target.name.substring(8, 9))].monetary)
					amountAndCurrencyElements[parseInt(event.target.name.substring(8, 9)) - 1].amount =
						addDecimalAmount();
				else
					amountAndCurrencyElements[parseInt(event.target.name.substring(8, 9)) - 1].amount =
						addIntegerAmount();
				setCurrenciesAndAmountsElements(amountAndCurrencyElements);
			}
		}
	};

	const addDecimalAmount = () => {
		return (
			<div className={classes.addRoomDialogItem}>
				<div className={classes.labelTextContainer}>
					<span className={classes.amountLabel}>Amount Per Code</span>
				</div>
				<TextField
					variant='outlined'
					size='small'
					label='Amount Per Code'
					onChange={handleChangeAmountPerCode}
					id='formatted-numberformat-input'
					name={'amount' + (initialAmountIndex + 1)}
					InputProps={{
						inputComponent: DecimalFormatter as any
					}}
				/>
			</div>
		);
	};

	const addIntegerAmount = () => {
		return (
			<div className={classes.addRoomDialogItem}>
				<div className={classes.labelTextContainer}>
					<span className={classes.amountLabel}>Amount Per Code</span>
				</div>
				<TextField
					variant='outlined'
					size='small'
					label='Amount Per Code'
					onChange={handleChangeAmountPerCode}
					id='formatted-numberformat-input'
					name={'amount' + (initialAmountIndex + 1)}
					InputProps={{
						inputComponent: IntegerFormatter as any
					}}
				/>
			</div>
		);
	};

	const listNameChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setlistName(event.target.value);
	};

	const validityPeriodChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
		setExpiration({
			expirationDate: 'All',
			validityPeriod: event.target.value
		});
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues({
			...values,
			[event.target.name]: event.target.value
		});
	};

	const handleChangeAmountPerCode = (event: React.ChangeEvent<HTMLInputElement>) => {
		const items = currenciesAndAmounts.slice();
		items[parseInt(event.target.name.substring(6, 7))].amount = event.target.value;
		setCurrenciesAndAmounts(items);
	};

	function renderServices() {
		const servicesArray: any[] = [];
		services.forEach((service: any) => {
			if (service.active)
				servicesArray.push(<MenuItem value={service.id}>{service.name}</MenuItem>);
		});

		return servicesArray;
	}

	function renderCurrencyTypesForSelect() {
		const currencyTypesArray: any[] = [];

		currencyTypes.forEach((currencyType: any) => {
			currencyTypesArray.push(
				<MenuItem value={currencyType.id}>
					{currencyType.name}
					{!currencyType.description ? '' : ` - ${currencyType.description}`}
				</MenuItem>
			);
		});

		return currencyTypesArray;
	}

	const pageOneCompleted = () => {
		if (
			isEmpty(listName.trim()) ||
			isEmpty(service.trim()) ||
			isEmpty(values.totalCodes) ||
			(expirationSelect === 'expirationDate' && expiration?.expirationDate === 'All') ||
			((expirationSelect === 'period' || expirationSelect === 'periodAfterFirstTransaction') &&
				isEmpty(expiration?.validityPeriod))
		)
			return true;
	};

	const renderCurrenciesAndAmounts = () => {
		const currenciesAndAmountsSpans: any[] = [];
		currenciesAndAmounts.forEach((element, index) => {
			if (element.currencyId !== '' && element.amount !== '') {
				currenciesAndAmountsSpans.push(
					<>
						<div className={classes.addRoomDialogItem}>
							<span className={classes.addValueCodeListItemLabel}>Currency</span>
							<span className={classes.addValueCodeListItemLabelBold}>{element.currencyName}</span>
						</div>
						<div className={classes.addRoomDialogItem}>
							<span className={classes.addValueCodeListItemLabel}>Amount Per Code</span>
							<span className={classes.addValueCodeListItemLabelBold}>
								<NumberFormat
									value={element.amount}
									thousandSeparator
									allowNegative={false}
									fixedDecimalScale
									displayType='text'
								/>
							</span>
						</div>
						<div className={classes.subtitle}></div>
					</>
				);
			}
		});
		return currenciesAndAmountsSpans;
	};

	const renderCurrencyElements = () => {
		const currenciesAndAmountsElement: any[] = [];
		currenciesAndAmountsElements.forEach((data) => {
			currenciesAndAmountsElement.push(data.currency, data.amount, data.deleteButtons);
		});
		return currenciesAndAmountsElement;
	};

	const removeElement = (index: any) => {
		setInitialAmountIndex((prevState) => prevState - 1);
		const test = currenciesAndAmountsElements;
		const test2 = currenciesAndAmounts.slice();
		test.splice(index, 1);
		test2[index + 1].amount = '';
		test2[index + 1].currencyId = '';
		test2[index + 1].currencyName = '';
		setCurrenciesAndAmountsElements(test);
		setCurrenciesAndAmounts(test2);
		setCurrencyTypesCountToSelect((prevState) => prevState + 1);
	};

	const addCurrencyType = () => {
		if (currencyTypesCountToSelect > 1) {
			const initialDeposit: InitialDeposit = {
				index: initialAmountIndex,
				currency: (
					<>
						<div className={classes.addRoomDialogItem}>
							<div className={classes.labelTextContainer}>
								<span className={classes.labelWithHelpText}>Currency</span>
								<span className={classes.helpText}>Select all options that apply.</span>
							</div>
							<FormControl variant='outlined'>
								<InputLabel htmlFor='currency'>Currency</InputLabel>
								<Select
									onChange={handleSelectChange}
									label='Currency'
									inputProps={{
										name: 'currency' + (initialAmountIndex + 1),
										id: 'outlined-select-currency'
									}}
								>
									<MenuItem value=''>
										<em>Select the currency...</em>
									</MenuItem>
									{renderCurrencyTypesForSelect()}
								</Select>
							</FormControl>
						</div>
					</>
				),
				amount: (
					<div className={classes.addRoomDialogItem}>
						<div className={classes.labelTextContainer}>
							<span className={classes.amountLabel}>Amount Per Code</span>
						</div>
						<TextField
							variant='outlined'
							size='small'
							label='Amount Per Code'
							disabled={true}
							id='formatted-numberformat-input'
							name={'amount'}
							InputProps={{
								inputComponent: IntegerFormatter as any
							}}
						/>
					</div>
				),
				deleteButtons: (
					<>
						<div>
							<div className={classes.deleteContainer}>
								<Button
									onClick={() => {
										removeElement(initialAmountIndex);
									}}
									size='small'
									variant='contained'
									color='default'
									endIcon={<Delete />}
									classes={{
										root: classes.deleteBtnRoot,
										label: classes.deleteBtnTxt
									}}
								>
									Delete
								</Button>
							</div>
							<div className={classes.subtitle}></div>
						</div>
					</>
				)
			};
			setCurrencyTypesCountToSelect((prevState) => prevState - 1);
			setInitialAmountIndex((prevState) => prevState + 1);
			const test = currenciesAndAmountsElements;
			test[initialAmountIndex] = initialDeposit;
			setCurrenciesAndAmountsElements(test);
		} else {
			notify('No more currency types available for the selected service.', 'warning');
		}
	};

	const cancelClicked = () => {
		setlistName('');
		setLocation('');
		setService('');
		setValues({
			totalCodes: '',
			amountPerCode: ''
		});
		setStep(1);
		setCurrenciesAndAmounts([]);
		setCurrenciesAndAmountsElements([]);
		addValueCodeListClosed();
	};

	const goToReview = () => {
		let showInitialDepositsTitle = false;
		currenciesAndAmounts.forEach((selectedElement) => {
			currencyTypes.forEach((element) => {
				if (selectedElement.currencyId === element.id) selectedElement.currencyName = element.name;
			});
			if (selectedElement.currencyId !== '' && selectedElement.amount !== '') {
				showInitialDepositsTitle = true;
			}
		});
		const valueArr = currenciesAndAmounts.map(function (item) {
			return item.currencyId;
		});
		valueArr.forEach((element, index) => {
			if (element === '') valueArr.splice(index, 1);
		});
		const isDuplicate = valueArr.some(function (item, idx) {
			return valueArr.indexOf(item) !== idx;
		});
		if (isDuplicate && showInitialDepositsTitle)
			notify('You have duplicated currency types', 'warning');
		else setStep(3);
		setShowInitialDepositsTitle(showInitialDepositsTitle);
	};

	const goToPageTwo = () => {
		dataProvider
			.getOne('valuecodelists', { id: listName })
			.then(({ data }) => {
				if (data.id !== undefined && data.message === undefined)
					notify('The list name already exists.', 'warning');
			})
			.catch((error: HttpError) => {
				if (error.status === 404) setStep(2);
			});
	};

	const onChangeExpirationDate = (date: any) => {
		const formatDate = format(date, 'MM/dd/yyyy');
		setExpiration({
			expirationDate: formatDate,
			validityPeriod: null
		});
	};

	useEffect(() => {
		let locationsArray: any[] = [];
		locationsArray = locations
			.filter((location: any) => location.parentId == null)
			.map((loc: any) => {
				return {
					label: loc.name,
					value: loc.id,
					checked: false,
					children:
						loc.childrenLocations === 0
							? []
							: locations
									.filter((childrenLocation: any) => childrenLocation.parentId === loc.id)
									.map((childLoc: any) => {
										return {
											label: childLoc.name,
											value: childLoc.id,
											checked: false
										};
									})
				};
			});
		setLocationsToSelect(locationsArray);
	}, [locations]);

	const locationChanged = (currentNode: any) => {
		const location = currentNode.value;
		const locationsArray = locationsToSelect.slice();
		locationsArray.forEach((loc: any) => {
			if (loc.value === location) {
				loc.checked = currentNode.checked;
			} else loc.checked = false;
			loc.children.forEach((childLoc: any) => {
				if (childLoc.value === location) {
					childLoc.checked = currentNode.checked;
				} else childLoc.checked = false;
			});
		});
		setLocationsToSelect(locationsArray);
		setLocation(currentNode.value);
		setSelectedLocation(currentNode.label);
	};

	return (
		<Dialog
			fullScreen
			open={openAddValueCodeList}
			onClose={addValueCodeListClosed}
			TransitionComponent={Transition}
		>
			<AppBar className={classes.addRoomDialogAppBar}>
				<Toolbar>
					<IconButton aria-label='back' onClick={cancelClicked}>
						<ArrowBackIcon className={classes.backIcon} />
					</IconButton>
					<Typography className={classes.addRoomDialogTitle}>Create List</Typography>
					{step === 1 && (
						<Button
							onClick={cancelClicked}
							size='small'
							variant='contained'
							color='default'
							classes={{
								root: classes.cancelBtnRoot,
								label: classes.cancelBtnTxt
							}}
						>
							Cancel
						</Button>
					)}
					{(step === 2 || step === 3) && (
						<Button
							onClick={() => setStep(step - 1)}
							size='small'
							variant='contained'
							color='default'
							classes={{
								root: classes.cancelBtnRoot,
								label: classes.cancelBtnTxt
							}}
						>
							Back
						</Button>
					)}
					{step === 1 && (
						<Button
							disabled={pageOneCompleted()}
							onClick={goToPageTwo}
							size='small'
							color='secondary'
							variant='contained'
							classes={{
								root: classes.nextBtnRoot,
								label: classes.yesBtnTxt,
								disabled: classes.addRoomBtnDisabled
							}}
						>
							Next
						</Button>
					)}
					{step === 2 && currenciesAndAmounts.length > 0 && (
						<Button
							onClick={goToReview}
							size='small'
							color='secondary'
							variant='contained'
							classes={{
								root: classes.nextBtnRoot,
								label: classes.yesBtnTxt,
								disabled: classes.addRoomBtnDisabled
							}}
						>
							Next
						</Button>
					)}
					{step === 2 && currenciesAndAmounts.length === 0 && (
						<Button
							onClick={() => setStep(step + 1)}
							size='small'
							color='secondary'
							variant='contained'
							classes={{
								root: classes.nextBtnRoot,
								label: classes.yesBtnTxt,
								disabled: classes.addRoomBtnDisabled
							}}
						>
							Skip
						</Button>
					)}
					{step === 3 && (
						<Button
							disabled={pageOneCompleted()}
							onClick={onCreateListClicked}
							size='small'
							color='secondary'
							variant='contained'
							classes={{
								root: classes.yesBtnRoot,
								label: classes.yesBtnTxt,
								disabled: classes.addRoomBtnDisabled
							}}
						>
							create list
						</Button>
					)}
				</Toolbar>
			</AppBar>
			<div className={classes.showLayout}>
				<div id='my_menu' className={classes.myMenu}>
					<div>
						<button
							onClick={() => {
								setStep(1);
							}}
							className={clsx(
								step === 1 || step === 2 || step === 3 ? classes.stepActive : classes.stepInactive
							)}
						>
							1
						</button>
						<button
							onClick={() => {
								setStep(1);
							}}
							className={clsx(
								step === 1 || step === 2 || step === 3
									? classes.stepButtonActive
									: classes.stepButtonInactive
							)}
						>
							List Information
						</button>
					</div>
					<div
						className={clsx(
							step === 2 || step === 3 ? classes.stepDividerActive : classes.stepDividerInactive
						)}
					></div>
					<div>
						<button
							disabled={pageOneCompleted()}
							onClick={goToPageTwo}
							className={clsx(step === 2 || step === 3 ? classes.stepActive : classes.stepInactive)}
						>
							2
						</button>
						<button
							disabled={pageOneCompleted()}
							onClick={goToPageTwo}
							className={clsx(
								step === 2 || step === 3 ? classes.stepButtonActive : classes.stepButtonInactive
							)}
						>
							Initial Deposits
						</button>
					</div>
					<div
						className={clsx(step === 3 ? classes.stepDividerActive : classes.stepDividerInactive)}
					></div>
					<div>
						<button
							disabled={pageOneCompleted()}
							onClick={goToReview}
							className={clsx(step === 3 ? classes.stepActive : classes.stepInactive)}
						>
							3
						</button>
						<button
							disabled={pageOneCompleted()}
							onClick={goToReview}
							className={clsx(step === 3 ? classes.stepButtonActive : classes.stepButtonInactive)}
						>
							Review
						</button>
					</div>
				</div>
				<div className={classes.steps}>
					{step === 1 && (
						<div>
							<div className={classes.subtitle}>List Information</div>
							<div>
								<div className={classes.addRoomDialogItem}>
									<span className={classes.addValueCodeListItemLabel}>List Name</span>
									<TextField
										id='outlined-helperText'
										variant='outlined'
										label='List Name'
										size='small'
										onChange={listNameChanged}
										value={listName}
									/>
								</div>

								<div className={classes.addRoomDialogItem}>
									<span className={classes.addValueCodeListItemLabel}>Service</span>
									<FormControl variant='outlined'>
										<InputLabel htmlFor='service'>Service</InputLabel>
										<Select
											value={service}
											onChange={handleSelectChange}
											label='Service'
											inputProps={{
												name: 'service',
												id: 'outlined-age-native-simple'
											}}
										>
											<MenuItem value=''>
												<em>Select a service...</em>
											</MenuItem>
											{renderServices()}
										</Select>
									</FormControl>
								</div>

								<div className={classes.addRoomDialogItem}>
									<div className={classes.labelTextContainer}>
										<span className={classes.labelWithHelpText}>Location</span>
										<span className={classes.helpText}>Optional.</span>
									</div>
									<DropdownTreeSelect
										data={locationsToSelect}
										mode='radioSelect'
										className={classes.locationsSelect}
										onChange={locationChanged}
									/>
								</div>

								<div className={classes.addRoomDialogItem}>
									<span className={classes.addValueCodeListItemLabel}>Total Number of Codes</span>
									<TextField
										variant='outlined'
										size='small'
										label='Total Number of Codes'
										value={values.totalCodes}
										onChange={handleChange}
										name='totalCodes'
										id='formatted-numberformat-input'
										InputProps={{
											inputComponent: TotalCodesFormatter as any
										}}
									/>
								</div>

								<div className={classes.addRoomDialogItem}>
									<label className={classes.addValueCodeListItemLabel}>Codes Expiration</label>
									<FormControl variant='outlined'>
										<InputLabel htmlFor='service'>Expiration</InputLabel>
										<Select
											value={expirationSelect}
											onChange={handleSelectChange}
											label='Expiration'
											inputProps={{
												name: 'expiration',
												id: 'outlined-age-native-simple'
											}}
										>
											<MenuItem value='noExpiration'>No Expiration</MenuItem>
											<MenuItem value='expirationDate'>Expires on Specific Date</MenuItem>
											<MenuItem value='period'>Valid for Set Period</MenuItem>
											<MenuItem value='periodAfterFirstTransaction'>
												Valid for Set Period from First Transaction
											</MenuItem>
										</Select>
									</FormControl>
								</div>

								{(expirationSelect === 'period' ||
									expirationSelect === 'periodAfterFirstTransaction') && (
									<div className={classes.addRoomDialogItem}>
										<div className={classes.labelTextContainer}>
											<label className={classes.labelWithHelpText}>Validity Period</label>
											<span className={classes.helpText}>In seconds.</span>
										</div>
										<TextField
											id='outlined-helperText'
											variant='outlined'
											label='Validity Period'
											size='small'
											onChange={validityPeriodChanged}
											value={expiration?.validityPeriod}
											InputProps={{
												inputComponent: IntegerFormatter as any
											}}
										/>
									</div>
								)}
								{expirationSelect === 'expirationDate' && (
									<div className={classes.addRoomDialogItem}>
										<span className={classes.addValueCodeListItemLabel}>Expiration Date</span>
										<DatePicker
											className={classes.wrapper}
											calendarClassName={classes.calendar}
											dateFormat={'MM/dd/yyyy'}
											minDate={new Date()}
											value={expiration?.expirationDate}
											onChange={onChangeExpirationDate}
											onInputClick={() => setOpenCalendar(true)}
											onClickOutside={() => setOpenCalendar(false)}
											open={openCalendar}
											isClearable
											customInput={<CustomInput label={'Expiration Date'} labelWidth={120} />}
										/>
									</div>
								)}
							</div>
						</div>
					)}
					{currenciesAndAmounts.length > 0 && (
						<div className={clsx(step === 2 ? '' : classes.hide)}>
							<div className={classes.subtitle}>Initial Deposits</div>
							<div className={classes.addRoomDialogItem}>
								<div className={classes.labelTextContainer}>
									<span className={classes.labelWithHelpText}>Currency</span>
									<span className={classes.helpText}>Select all options that apply.</span>
								</div>
								<FormControl variant='outlined'>
									<InputLabel htmlFor='currency'>Currency</InputLabel>
									<Select
										onChange={handleSelectChange}
										label='Currency'
										value={currenciesAndAmounts[0].currencyId}
										inputProps={{
											name: 'currency0',
											id: 'outlined-select-currency'
										}}
									>
										<MenuItem value=''>
											<em>Select the currency...</em>
										</MenuItem>
										{renderCurrencyTypesForSelect()}
									</Select>
								</FormControl>
							</div>
							<div className={classes.addRoomDialogItem}>
								<div className={classes.labelTextContainer}>
									<span className={classes.amountLabel}>Amount Per Code</span>
								</div>
								<TextField
									variant='outlined'
									size='small'
									label='Amount Per Code'
									disabled={
										currenciesAndAmounts[0].currencyId === '' ||
										currenciesAndAmounts[0].currencyId === null
									}
									value={
										currenciesAndAmounts[0].currencyId === '' ? '' : currenciesAndAmounts[0].amount
									}
									onChange={handleChangeAmountPerCode}
									id='formatted-numberformat-input'
									name='amount0'
									InputProps={{
										inputComponent: currenciesAndAmounts[0].monetary
											? (DecimalFormatter as any)
											: (IntegerFormatter as any)
									}}
								/>
							</div>
							<div className={classes.subtitle}></div>
							{renderCurrencyElements()}
							<div className={classes.addRoomDialogItem}>
								<Button
									onClick={addCurrencyType}
									size='small'
									variant='contained'
									startIcon={<AddIcon />}
									color='default'
									classes={{
										root: classes.addCuurencyTypeBtnRoot,
										label: classes.cancelBtnTxt
									}}
								>
									Add currency type
								</Button>
							</div>
						</div>
					)}
					{currenciesAndAmounts.length === 0 && (
						<div className={clsx(step === 2 ? '' : classes.hide)}>
							<div className={classes.subtitle}>No currency types found.</div>
						</div>
					)}
					{step === 3 && (
						<div>
							<div className={classes.subtitle}>List Information</div>
							<div className={classes.addRoomDialogItem}>
								<span className={classes.addValueCodeListItemLabel}>List Name</span>
								<span className={classes.addValueCodeListItemLabelBold}>{listName}</span>
							</div>
							<div className={classes.addRoomDialogItem}>
								<span className={classes.addValueCodeListItemLabel}>Service</span>
								<span className={classes.addValueCodeListItemLabelBold}>{selectedService}</span>
							</div>
							{location !== '' && (
								<div className={classes.addRoomDialogItem}>
									<span className={classes.addValueCodeListItemLabel}>Location</span>
									<span className={classes.addValueCodeListItemLabelBold}>{selectedLocation}</span>
								</div>
							)}
							<div className={classes.addRoomDialogItem}>
								<span className={classes.addValueCodeListItemLabel}>Total Number of Codes</span>
								<span className={classes.addValueCodeListItemLabelBold}>
									<NumberFormat
										value={values.totalCodes}
										thousandSeparator
										isNumericString
										decimalScale={0}
										displayType={'text'}
									/>
								</span>
							</div>
							<div className={classes.addRoomDialogItem}>
								<span className={classes.addValueCodeListItemLabel}>Expiration</span>
								{expirationSelect === 'noExpiration' && (
									<span className={classes.addValueCodeListItemLabelBold}>No expiration</span>
								)}
								{expirationSelect === 'period' && (
									<span className={classes.addValueCodeListItemLabelBold}>
										{expiration?.validityPeriod} sec.
									</span>
								)}
								{expirationSelect === 'periodAfterFirstTransaction' && (
									<span className={classes.addValueCodeListItemLabelBold}>
										{expiration?.validityPeriod} sec. from First Transaction
									</span>
								)}
								{expirationSelect === 'expirationDate' && (
									<span className={classes.addValueCodeListItemLabelBold}>
										{expiration?.expirationDate}
									</span>
								)}
							</div>
							{showInitialDepositsTitle && <div className={classes.subtitle}>Initial Deposits</div>}
							{renderCurrenciesAndAmounts()}
						</div>
					)}
				</div>
			</div>
		</Dialog>
	);
};
